import React from 'react'
import { Link } from 'react-router-dom'
import '../index.css'
const First = () => {
  return (
    <div className="flex flex-col items-center p-16 max-[600px]:p-4 max-[330px]:p-0 gap-24 text-slate-300">
      <div className='w-[50%] max-[600px]:w-[80%]'>
        <Link to="/" className='absolute left-[100px] text-3xl text-primary max-[600px]:hidden'>Home</Link>
        <h1 className=' text-wrap text-center text-4xl max-[600px]:text-2xl'>
          Top 5 most Chill and Relaxing Hiphop Albums
        </h1>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>1. A Tribe Called Quest - Midnight Marauders </h2>
          <img src='top_5_most_relaxing_albums/midnight.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>Midnight Marauders</span> is a classic album by <span className='text-secondary'>A Tribe Called Quest</span> that showcases their unique blend of jazz-infused hip hop. The production on this album is top-notch, with smooth beats and infectious grooves that make for a perfect backdrop to the group's introspective and socially conscious lyrics.

          Tracks like <span className='text-primary'>"Award Tour"</span> and <span className='text-primary'>"Electric Relaxation"</span> are standout cuts that capture the laid-back vibe of the group, while songs like <span className='text-primary'>"Sucka N***a"</span> tackle more serious topics with a raw energy. The chemistry between Q-Tip and Phife Dawg is undeniable, with their back-and-forth rhymes and clever wordplay elevating each track.

          Overall, Midnight Marauders is a timeless album that effortlessly blends soulful samples, tight lyricism, and positive vibes. It's a must-listen for any hip hop fan and a reminder of A Tribe Called Quest's lasting impact on the genre.

        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>2. Modal Soul – Nujabes  </h2>
          <img src='top_5_most_relaxing_albums/modalsoul.webp' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>Modal Soul</span> by <span className='text-secondary'>Nujabes</span> is a masterpiece that transcends genres and leaves a lasting impact on anyone who listens to it. The album seamlessly blends elements of hip hop, jazz, and electronic music to create a unique and captivating sound that is both soothing and energizing.

          Nujabes' production on Modal Soul is nothing short of brilliant, with lush, soulful beats that are both intricate and incredibly groovy. Tracks like <span className='text-primary'>"Feather"</span> and <span className='text-primary'>"Luv Sic"</span> showcase his ability to create atmospheric, emotionally resonant music that is perfect for chilling out or introspective listening.

          The album features collaborations with a variety of talented artists, including Shing02, Substantial, and Cise Starr, whose contributions add depth and diversity to the overall sound of Modal Soul. Each track feels carefully crafted and thoughtfully composed, resulting in a cohesive and immersive listening experience.

          Overall, Modal Soul is a timeless and essential album that showcases Nujabes' unparalleled talent as a producer and musician. It's a true gem that continues to inspire and influence artists across different genres, making it a must-listen for anyone who appreciates innovative and soul-stirring music.
        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col  gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>3. Warren G - Regulate...G funk era</h2>
          <img src='top_5_most_relaxing_albums/regulate.webp' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>Regulate...G Funk Era</span> by <span className='text-secondary'>Warren G</span> is a classic West Coast hip hop album that helped define the G-funk sound of the 1990s. Released in 1994, the album features Warren G's smooth flow and laid-back delivery over funky, melodic beats that are instantly recognizable and undeniably catchy.

          The standout track on the album is, of course, "Regulate," featuring Nate Dogg, which became a massive hit and remains a hip hop anthem to this day. The song perfectly encapsulates the G-funk era with its infectious hook, storytelling lyrics, and impeccable production. Other tracks like <span className='text-primary'>"This D.J."</span> and <span className='text-primary'>"Do You See"</span> further showcase Warren G's skills as a rapper and producer, with their slick grooves and West Coast vibe.

          One of the strengths of Regulate...G Funk Era is its consistency and cohesiveness. Warren G's smooth delivery and ear for catchy hooks tie the album together, creating a seamless listening experience from start to finish. The production, featuring live instrumentation and samples of classic R&B and funk tunes, adds depth and texture to the overall sound of the album.

          Overall, Regulate...G Funk Era is a must-listen for any fan of West Coast hip hop or 90s rap music. Warren G's debut album remains a standout in his discography and a timeless classic that continues to resonate with listeners decades after its release.

        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>4.Pete Rock & CL Smooth - Mecca and the Soul Brother</h2>
          <img src='top_5_most_relaxing_albums/mecca.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"Mecca and the Soul Brother"</span> is the debut album by the legendary hip hop duo <span className='text-secondary'>Pete Rock & CL Smooth</span>, released in 1992. The album is widely regarded as a classic in the genre and is praised for its innovative production, smooth rhymes, and soulful vibes.

          One of the standout tracks on the album is <span className='text-primary'>"They Reminisce Over You (T.R.O.Y.)"</span> a heartfelt tribute to their deceased friend Trouble T-Roy. The song features a melancholic saxophone sample and showcases CL Smooth's introspective lyrics and Pete Rock's impeccable production skills. It remains one of the most iconic tracks in hip hop history.

          Throughout the album, Pete Rock's production shines, as he seamlessly blends jazz, soul, and funk samples to create a rich, textured sound that sets the perfect backdrop for CL Smooth's laid-back flow. Tracks like <span className='text-primary'>"Lots of Lovin'"</span> and <span className='text-primary'>"Straighten It Out"</span> exemplify this synergy, with their smooth, soulful beats and thoughtful lyrics.

          "Mecca and the Soul Brother" is not just a collection of great songs, but a cohesive body of work that showcases the duo's chemistry and talent. The album has stood the test of time and continues to be a favorite among hip hop fans for its timeless production and lyrical depth.

          Overall, "Mecca and the Soul Brother" is a masterpiece of '90s hip hop that deserves a place in every music lover's collection. Pete Rock & CL Smooth's debut album remains a landmark in the genre, and its influence can still be felt in hip hop music today. If you're a fan of classic hip hop with soulful vibes and top-notch production, this album is a must-listen.

        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>5. Kid Cudi - Man on the Moon</h2>
          <img src='top_5_most_relaxing_albums/kidcudi.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"Man on the Moon: The End of Day"</span> is the debut studio album by American rapper <span className='text-secondary'>Kid Cudi</span>, released in 2009. The album received critical acclaim for its introspective lyrics, unique sound, and innovative production, and is considered a modern classic in the hip hop genre.

          One of the standout aspects of the album is Kid Cudi's introspective and deeply personal lyrics, which often touch on themes of loneliness, addiction, and self-discovery. Tracks like <span className='text-primary'>"Soundtrack 2 My Life"</span> and <span className='text-primary'>"Day 'N' Nite"</span> showcase Kid Cudi's vulnerability and raw emotion, creating a sense of connection with listeners.

          The production on "Man on the Moon" is also a highlight, with Kid Cudi collaborating with producers like Emile Haynie, Plain Pat, and Kanye West to create a sound that is atmospheric, ethereal, and otherworldly. The album blends elements of hip hop, rock, and electronic music, creating a sonic landscape that perfectly complements Kid Cudi's introspective lyrics.

          The album's concept and structure are also noteworthy, as it is divided into five acts that follow Kid Cudi's journey through the night and his struggles with mental health and self-discovery. This narrative structure adds depth and cohesion to the album, making it a cohesive and immersive listening experience.

          Overall, "Man on the Moon: The End of Day" is a groundbreaking album that pushed the boundaries of hip hop and showcased Kid Cudi's unique talent and vision. It remains a fan favorite and a critical darling, praised for its emotional depth, innovative sound, and artistic ambition. If you're a fan of introspective and atmospheric hip hop music, this album is a must-listen.

        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

export default First