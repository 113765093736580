import React from 'react'
import { Link } from 'react-router-dom'

const Top_5_Underrated_Biggie_Smalls = () => {
  return (
    <div className="flex flex-col items-center p-16 max-[600px]:p-4 max-[330px]:p-0 gap-24 text-slate-300">
      <div className='w-[50%] max-[600px]:w-[80%]'>
        <Link to="/" className='absolute left-[100px] text-3xl text-primary max-[600px]:hidden'>Home</Link>
        <h1 className=' text-wrap text-center text-4xl max-[600px]:text-2xl'>
          Top 5 Underrated Biggie Smalls Songs
        </h1>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>5. Kick in the Door </h2>
          <img src='/top_5_underrated_biggie_smalls/kickdownthedoor.jpeg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"Kick in the Door"</span> by Biggie Smalls is a bold track where he confidently calls out his rivals.
          With DJ Premier's cool beats, Biggie's rhymes hit strong as he asserts his dominance in the rap scene.
          It's a classic that shows off his talent and swagger, making it a must-listen for hip-hop fans.
        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>4. Party and Bullshit</h2>
          <img src='/top_5_underrated_biggie_smalls/partyandbullshit.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"Party and Bullshit"</span> by Biggie Smalls is a classic party track that captures the fun vibe of the '90s hip-hop scene.
          With catchy beats and Biggie's smooth flow, it's all about having a good time and forgetting the drama.
          It's a feel-good anthem that still gets people dancing today.
        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col  gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>3. Machine Gun Funk</h2>
          <img src='/top_5_underrated_biggie_smalls/machinegunfunk.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"Machine Gun Funk"</span> by Biggie Smalls is a jam that's all about living large. With its funky beats and Biggie's slick rhymes,
          it's a track that makes you want to groove. It's a classic from Biggie's collection, showing off his style and making you bob your head along.
        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>2. One More Chance</h2>
          <img src='/top_5_underrated_biggie_smalls/onemorechance.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>
          <span className='text-primary'>"One More Chance"</span> by Biggie Smalls is a smooth jam that's all about romance. With its catchy beats and Biggie's smooth flow,
          it's a song that makes you want to slow dance. It's a hit from Biggie's stash, showing off his charm and making you feel good.
        </div>
        <div>
        </div>
      </div>
      <div className='self-start items-center flex flex-row max-[600px]:flex-col gap-12'>
        <div className='flex flex-col gap-4 min-w-[500px] max-[600px]:min-w-[300px]'>
          <h2 className='text-2xl'>1.Who Shot Ya</h2>
          <img src='/top_5_underrated_biggie_smalls/whoshotya.jpg' className='w-[500px]' />
        </div>
        <div className='text-2xl'>

          <span className='text-primary'>"Who Shot Ya"</span> by Biggie Smalls is a hardcore track that's all about street life. With its intense beats and Biggie's tough rhymes,
          it's a song that gets you hyped up. It's a standout from Biggie's collection, showcasing his raw style and making you feel the energy.
        </div>
        <div>
        </div>
      </div>
    </div>
  )
}

export default Top_5_Underrated_Biggie_Smalls