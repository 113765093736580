import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link, Path } from 'react-router-dom';

type BlogType = {
  link: Partial<Path> | string,
  title: string,
  image: string
}

const Posts: Array<BlogType> = [
  {
    link: "/top_5_most_relaxing_albums",
    title: "Top 5 Most Chill and Relaxing Hiphop Albums",
    image: "top_5_most_relaxing_albums/midnight.jpg"
  },
  {
    link: "/top_5_underrated_biggie_smalls",
    title: "Top 5 Underrated Biggie Smalls Songs",
    image: "top_5_underrated_biggie_smalls/biggie.webp"
  }
]

function App() {
  return (
    <div className="flex flex-col items-center p-16 max-[600px]:p-4 max-[330px]:p-0 gap-24">
      <div className='w-[50%] max-[600px]:w-[80%]'>
        <h1 className=' text-wrap text-center text-4xl max-[600px]:text-2xl'>
          Welcome to <span className=' text-primary'>90soldschool.com.</span> The best blog for Old School Hip Hop content in 2024.
        </h1>
      </div>
      <div className='self-start flex flex-col gap-4 max-[600px]:items-center'>
        <h2 className='text-4xl'>Recent Posts</h2>
        <div className='flex flex-row flex-wrap gap-6 max-[600px]:justify-center'>
          {Posts.map((post) => (
            <BlogCard {...post} />
          ))}
        </div>
      </div>
    </div>
  );
}

function BlogCard({ link, title, image }: BlogType) {
  return (
    <div className='border rounded-md w-[20%] max-[600px]:w-[80%]'>
      <Link to={link}>
        <img className='w-full h-[50%] max-[600px]:h-fit' src={image} />
        <div>
          <h3 className=' text-2xl p-4'>{title}</h3>
        </div>
      </Link>

    </div>
  )
}

export default App;
